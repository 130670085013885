import React from "react"
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';

const styles = theme => ({
  root: {
    display: 'block',
    position: 'relative',
    maxWidth: 600,
    maxHeight: 200,
    fontFamily: 'Arial, sans-serif',
    padding: 12,
    verticalAlign: 'top',
    borderRadius: 2,
    boxSizing: 'border-box',
    overflow: 'hidden',
    '& img[src="https://servedbyadbutler.com/error/blank.gif"]': {
      display: 'none'
    },
  },
  header: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  iconButton: {
    padding: 6
  },
  icon: {
    fontSize: 12
  },
  popupInfo: {
    padding: 12,
  },
  container: {
    '& .inline-product': {
      position: 'relative',
      maxWidth: 600,
      lineHeight: 1.54,
      verticalAlign: 'top',
    },
    '& .inline-product-title': {
      display: 'inline-block',
      fontSize: 18,
    },
    '& .inline-product-title a': {
      color: '#001ba0',
      textDecoration: 'none',
    },
    '& .inline-product-title a:hover': {
      textDecoration: 'underline',
    },
    '& .inline-product-snippet': {
      wordWrap: 'break-word',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 1.54,
    },
    '& .inline-product-snippet:before': {
      content: 'Ad',
      color: '#636161',
      fontSize: 11,
      fontWeight: 600,
      border: '1px solid #a2a2a2',
      padding: '0 2px 0 2px',
      borderRadius: 4,
      marginRight: 6,
    }
  },
});

class InlineAdItem extends React.Component {
  state = {
    infoAnchorEl: null
  };

  handleInfoButtonClick = event => {
    event.preventDefault();
    this.setState({
      infoAnchorEl: event.currentTarget,
    });
  };

  handleInfoClose = event => {
    this.setState({
      infoAnchorEl: null,
    });
  };

  render() {
    const { containerId, classes, hidden } = this.props;
    const { infoAnchorEl } = this.state;
    const openInfo = Boolean(infoAnchorEl);

    return (
      <div className={classes.root} hidden={hidden}>
        <div className={classes.header}>
          <IconButton className={classes.iconButton} onClick={this.handleInfoButtonClick}>
            <InfoIcon className={classes.icon} />
          </IconButton>
          <Popover
            open={openInfo}
            anchorEl={infoAnchorEl}
            onClose={this.handleInfoClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            disableRestoreFocus
          >
            <div className={classes.popupInfo}>
              <h5 style={{ marginBottom: 6 }}>About this Ad</h5>
              <p style={{ margin: 0, fontSize: 'small' }}>This ad is based on your current search terms</p>
            </div>
          </Popover>
        </div>
        <div id={containerId} className={classes.container}></div>
      </div>
    );
  }
}

InlineAdItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InlineAdItem);

