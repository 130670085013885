
/**
 * @typedef Paramaters
 * @property {string} keywords
 * @property {string} cid
 * @property {number} entries
 * @property {number} page
 */

/** 
 * Creates a new Ebay Service Client.
 * @class
 */
class EbayClient {
  /**
   * Constructs a new Ebay Service Client instance.
   * @param {Options} opts
   * @return {Ebay}
   */
  constructor(opts) {
    this.SERVICE_URL_ = new URL('https://xhbhy8kgyl.execute-api.us-west-2.amazonaws.com/default/ebay-find-products');
    this.cid = opts.cid || '';
  }
  /**
   * Returns products based on keywords and filters.
   * 
   * @param {Parameters} params
   * @return {Promise}
   */
  getProducts(params = {}) {
    params.cid = params.cid || this.cid;
    const qs = new URLSearchParams(params).toString();
    const URL = `${this.SERVICE_URL_.toString()}?${qs}`;

    return fetch(URL, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json'
      },
    })
    .then(res => {
      if (!res.ok) {
        throw new Error('Failed to fetch products: Network response was not ok.');
      }
      return res.json();
    })
    .then(res => {
      return res[0].item
    })
    .catch(err => {
      console.error(err);
      return [];
    });
    
  }
}

export default EbayClient;
