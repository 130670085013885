import React from "react";
import ReactDOM from 'react-dom'
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SEO from "../components/seo"
import SearchAutosuggest from "../components/search-autosuggest";
import Logo from "../components/logo";
import SearchList from "../components/search-list";
import ShoppingList from "../components/shopping-list";

import shortid from "shortid";
import EbayClient from "../services/ebay";


const DESKTOP_LEFT_MARGIN = '186px';

const adsConfig = {
  top: {
    name: 'top',
    containerId: '#top-ad-container',
    id: '174190',
    setID: '360065',
  },
  inlineTop: {
    name: 'inlineTop',
    containerId: '#inline-ad-container-top',
    id: '174190',
    setID: '368099',
  },
  inlineMiddle: {
    name: 'inlineMiddle',
    containerId: '#inline-ad-container-middle',
    id: '174190',
    setID: '368100',
  },
  inlineBottom: {
    name: 'inlineBottom',
    containerId: '#inline-ad-container-bottom',
    id: '174190',
    setID: '360064',
  },
};

const styles = theme => ({
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: 20,
    height: 86,
  },
  topLogo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      width: DESKTOP_LEFT_MARGIN,
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
    }
  },
  searchBar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'normal',  
    }
  },
  navBar: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: 48,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    color: 'gray',
    [theme.breakpoints.up('md')]: {
      paddingLeft: DESKTOP_LEFT_MARGIN,
    }
  },
  extBar: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: 42,
    lineHeight: '42px',
    padding: '0 12px 0 12px',
    fontSize: 'small',
    color: 'gray',
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: DESKTOP_LEFT_MARGIN,
    }
  },
  content: {
    marginTop: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: DESKTOP_LEFT_MARGIN,
    }
  },
  section: {
    paddingBottom: 64,
  },
});

const AntTabs = withStyles({
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

class SearchPage extends React.Component {
  SEARCH_RESULT_COUNT = 10;
  SHOPPING_RESULT_COUNT = 10;
  ADBUTLER_DEBUG = false; // TODO: change to env variable

  constructor(props) {
    super(props);
    this.productIds = this.props.data.cards.edges.map(n => n.node.name);
    this.trackingParams = this.props.data.config.edges[0].node.tracking_param_list;
    this.state = {
      activeTab: 'search',
      results: [],
      shoppingResults: [],
      shoppingPage: 1,
      productcard: false,
      isLoading: true,
      isLoadingShoppingFeed: false,
      displayAds: {
        top: false,
        inlineTop: false,
        inlineMiddle: false,
        inlineBottom: false,
      },
      userHideAdsOverride: false,
    };
    // Set Ebay client tracking parameter.
    const pKey = this.trackingParams.find(p => {
      return this.URLParams.get(p);
    });
    this.ebayClient = new EbayClient({ cid: this.URLParams.get(pKey)});
  }

  async componentDidMount() {
    try {
      if (typeof window !== 'undefined') {
        await this.renderAd(adsConfig.top, window);
        await this.renderAd(adsConfig.inlineTop, window);
        await this.renderAd(adsConfig.inlineMiddle, window);
        await this.renderAd(adsConfig.inlineBottom, window);
      }
      await this.updateSearchResults(this.state.displayAds);
      await this.updateProductCard(this.productIds);
      const node = ReactDOM.findDOMNode(this);
      if (node instanceof HTMLElement) {
        this.appendTrackingParamsToOutboundLinks(
          this.getAllOutboundLinkElements(node),
          this.trackingParams
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      let prevPage = this.getPage(prevProps.location.search);
      let prevSearchQuery = this.getSearchQuery(prevProps.location.search);

      if (prevSearchQuery !== this.searchQuery) {
        try {
          if (prevState.activeTab === 'shopping') {
            await this.updateShoppingResults();
          }
          if (typeof window !== 'undefined') {
            await this.renderAd(adsConfig.top, window);
            await this.renderAd(adsConfig.inlineTop, window);
            await this.renderAd(adsConfig.inlineMiddle, window);
            await this.renderAd(adsConfig.inlineBottom, window);
          }
          await this.updateSearchResults(this.state.displayAds);
          await this.updateProductCard(this.productIds);
          const node = ReactDOM.findDOMNode(this);
          if (node instanceof HTMLElement) {
            this.appendTrackingParamsToOutboundLinks(
              this.getAllOutboundLinkElements(node)
            );
          }
        } catch (err) {
          console.error(err);
        }
      } else if (prevPage !== this.page) {
        try {
          // If ads were loaded on the previous page then display ads.
          await this.updateSearchResults(prevState.displayAds);
        } catch (err) {
          console.error(err);
        }
      } else {
        // Handles random URL parameter change on same query search.
        try {
          // If ads were loaded for the matching query then then display ads.
          await this.updateSearchResults(prevState.displayAds);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  get searchQuery() {
    return this.URLParams.get('q');
  }

  get page() {
    return Number(this.URLParams.get('page')) || 1;
  }

  get offset() {
    return (this.page - 1) * this.SEARCH_RESULT_COUNT;
  }

  get URLParams() {
    return new URLSearchParams(this.props.location.search);
  }

  getPage(params) {
    return new URLSearchParams(params).get('page') || 1;
  }

  getSearchQuery(params) {
    return new URLSearchParams(params).get('q');
  }

  /**
   * Updates search result items based on search query.
   * @param {Object} [displayAds]
   */
  async updateSearchResults(displayAds) {
    try {
      const searchResults = await fetchSearchResults(this.searchQuery, this.offset);
      this.setState((state, props) => ({
        results: searchResults,
        isLoading: false,
        displayAds: displayAds ? {...displayAds} : {
          top: false,
          inlineTop: false,
          inlineMiddle: false,
          inlineBottom: false,
        },
      }));
    } catch (err) {
      console.error(err);
      throw new Error(`Failed to update search results`);
    }
  }

  /**
   * Updates product card info box.
   * 
   * @param {String[]} productIds
   * @returns {Promise}
   */
  async updateProductCard(productIds) {
    const searchQuery = this.searchQuery.trim();
    if (!searchQuery.length || !/\w/g.test(searchQuery)) {
      return this.setState({
        productCard: false
      });
    }
    let regxQuery = new RegExp(searchQuery.replace(/\s/g, '-'), 'i');
    let productId = productIds.find(id => regxQuery.test(id));
    if (!productId) {
      return this.setState({
        productCard: false
      });
    }
    return fetchProductCard(productId)
      .then(res => {
        this.setState({
          productCard: {
            title: res.title,
            image: res.thumbnail,
            body: res.body.replace(/[\n\r]/g, '<br/>'),
            link: res.link
          }
        });
      })
      .catch(err => {
        console.error(err);
        return false;
      })
  }

  /**
   * Fetches and renders the ad.
   * 
   * @param {Object} config
   * @param {Window} w
   */
  async renderAd(config, w) {
    const adContainer = w.document.querySelector(config.containerId);
    // Clear content from ad container.
    while (adContainer && adContainer.lastChild) {
      adContainer.removeChild(adContainer.firstChild);
    }
    // Fetch and render ad content.  
    const adScriptTag = buildAdScript(config, window);
    return new Promise(resolve => {
      w.postscribe(adContainer, adScriptTag, {
        done: () => resolve()
      });
    })
      .then(() => {
        if (!adContainer.innerHTML.includes('error')) {
          if (this.ADBUTLER_DEBUG) {
            console.log('DEBUG: Ad matched keyword');
          }
          // Ad fetched successfully.
          this.displayAds(config.name, true);
        } else {
          if (this.ADBUTLER_DEBUG) {
            console.log('DEBUG: No Ad found matching keyword');
          }
          this.displayAds(config.name, false);  
        }
      })
      .catch(err => {
        this.displayAds(config.name, false);
        console.error(`Failed to render ad: ${err.message}`);
        return false;
      });
  }


  /**
   * Updates shopping results items based on search query.
   */
  async updateShoppingResults(page = 1) {
    try {
      this.setState({
        isLoadingShoppingFeed: true,
        shoppingPage: page,
        shoppingResults: []
      });
      const items = this.searchQuery.length ? await this.ebayClient.getProducts({
        keywords: this.searchQuery,
        page: page,
        entries: 10
      }) : [];
      this.setState({
        isLoadingShoppingFeed: false,
        shoppingResults: items
      });
    } catch (err) {
      console.error(err)
      throw new Error(`Failed to update shopping results`);
    }
  }

  /**
   * Returns all tracking parameters present on the page URL.
   * 
   * @returns {URLSearchParams}
   */
  getTrackingParams() {
    let params = new URLSearchParams();
    this.trackingParams.forEach(t => {
      let value = this.URLParams.get(t);
      if (value) {
        params.set(t, value);
      }
    });
    return params;
  }

  /**
   * Returns all outbound link elements.
   * 
   * @param {HTMLElement}
   * @returns {HTMLAnchorElement[]}
   */
  getAllOutboundLinkElements(nodeElement) {
    try {
      const host = nodeElement.ownerDocument.location.host;
      return Array.from(nodeElement.querySelectorAll('a'))
        .filter(link => !link.href.includes(host));
    } catch(err) {
      console.warn(`Couldn't get outbound links`);
      return [];
    }
  }

  /**
   * Appends tracking URL parameters present on the page URL to outbound link urls.
   * 
   * @param {HTMLAnchorElement[]} links
   * @returns {HTMLAnchorElement[]}
   */
  appendTrackingParamsToOutboundLinks(links) {
    let tp = this.getTrackingParams();
    return links.filter(Boolean).map(a => {
      let pl = new URLSearchParams(a.search);
      for (let key of tp.keys()) {
        pl.append(key, tp.get(key));
      }
      a.search = pl.toString();
      return a;
    });
  }

  /**
   * Set state for displaying ads.
   * 
   * @param {Boolean} bool
   */
  displayAds(key, bool) {
    this.setState((state, props) => ({
      displayAds: {
        ...state.displayAds,
        [key]: bool,
      }
    }));
  }

  /**
   * User override for displaying ads.
   * 
   * @param {Boolean} hideAds
   */
  userHideAdsOverride(hideAds) {
    this.setState((state, props) => ({
      userHideAdsOverride: hideAds
    }));
  }

  /**
   * Sets loading state.
   * 
   * @param {Boolean} isLoading 
   */
  setLoading(isLoading) {
    this.setState((state, props) => ({
      isLoading,
    }));
  }

  /**
   * Callback that handles user search changes.
   * Performs internal url navigation.
   * 
   * @param {String} query
   */
  handleSearchChange = (query) => {
    this.setLoading(true);
    var p = this.URLParams;
    if (query === this.searchQuery) {
      // Set random string URL param to prevent infinite loop on same query search.
      p.set('t', shortid.generate());
    }
    p.set('q', query);
    p.delete('page');
    return this.props.navigate(`/search/?${p.toString()}`);
  }

  /**
   * Callback that handles user result pagination.
   * Performs internal URL navigation.
   * 
   * @param {Number} offset
   */
  handleSearchPagination = (offset) => {
    this.setLoading(true);
    var p = this.URLParams;
    offset > 0 ? p.set('page', (offset / this.SEARCH_RESULT_COUNT) + 1) : p.set('page', 1);
    return this.props.navigate(`/search/?${p.toString()}`);
  }

  /**
   * Callback that handles user ad hidding.
   */
  handleUserHideAd = res => {
    this.userHideAdsOverride(true);
  }

  /**
 * Callback than handles shopping list pagination.
 */
  handleShoppingPagination = async (offset) => {
    const page = offset > 0 ? offset / this.SHOPPING_RESULT_COUNT + 1 : 1;
    this.updateShoppingResults(page);
  }

  /**
   * Callback that handles tab change.
   */
  handleTabChange = async (event, newValue) => {
    if (newValue !== this.state.activeTab) {
      this.setState({
        activeTab: newValue
      });
      if (newValue === 'shopping') {
        if (this.state.shoppingResults.length) {
          return;
        }
        this.updateShoppingResults();
      }
    }
  }

  render() {
    const {classes} = this.props;
    const TAB_TITLES = {
      search: 'Web results',
      shopping: `Shopping offers for ${this.searchQuery}`
    }

    return (
        <div className={classes.section}>
          <SEO title={`Search`} keywords={[`web search`]} />
          <Helmet>
            <script type="text/javascript" src="//get.searchswift.net/trackcl.js"></script>
          </Helmet>
          <div className={classes.topBar}>
            <div className={classes.topLogo}>
              <Logo />
            </div>
            <div className={classes.searchBar}>
              <SearchAutosuggest
                query={this.searchQuery}
                onEnterKey={this.handleSearchChange}
              />
            </div>
          </div>
          <div className={classes.navBar}>
          <AntTabs value={this.state.activeTab} onChange={this.handleTabChange}>
              <Tab label='Search' value={'search'} />
              <Tab label='Shopping' value={'shopping'}/>
            </AntTabs>
          </div>
          <div className={classes.extBar}>
            <span>{TAB_TITLES[this.state.activeTab]}</span>
          </div>
          <div className={classes.content}>
            <SearchList
              hidden={this.state.activeTab !== 'search'}
              items={this.state.results}
              offset={this.offset}
              onPageChange={this.handleSearchPagination}
              onLoad={this.handleSearchListLoaded}
              isLoading={this.state.isLoading}
              handleTopAdHidden={this.handleUserHideAd}
              displayAds={this.state.userHideAdsOverride ? false : this.state.displayAds}
              productCard={this.state.productCard}
            />
            <ShoppingList
              hidden={this.state.activeTab !== 'shopping'}
              items={this.state.shoppingResults}
              isLoading={this.state.isLoadingShoppingFeed}
              onPageChange={this.handleShoppingPagination}
              offset={(this.state.shoppingPage - 1) * this.SHOPPING_RESULT_COUNT}
            />
          </div>
        </div>
    );
  }
}

/**
 *  Builds the AdButler Ad script tag.
 * 
 * @param {Object} adConfig
 * @param {Window} w
 * @returns {HTMLElement}
 */
function buildAdScript(adConfig, w) {
  const config = {
    ID: adConfig.id,
    setID: adConfig.setID,
    type: 'js',
    size: '0x0',
    rnd: w.rnd || Math.floor(Math.random() * 10e6),
    abkw: w.abkw || '',
    sw: w.screen.width,
    sh: w.screen.height,
    spr: w.devicePixelRatio,
  };
  config.pid = w[`pid${config.setID}`] || config.rnd;
  config.plc = w[`plc${config.setID}`] || 0;
  return buildAdScriptTag(config);
}

/**
 * Builds and returns the Ad script tag.
 * 
 * @param {Object} conf
 * @returns {String}
 */
function buildAdScriptTag(conf) {
  return `<script src="https://servedbyadbutler.com/adserve/;ID=${conf.ID};` + 
         `size=${conf.size};setID=${conf.setID};type=js;sw=${conf.sw};sh=${conf.sh};` +
         `spr=${conf.spr};kw=${conf.abkw};pid=${conf.pid};place=${(conf.plc++)};` + 
         `rnd=${conf.rnd};click=CLICK_MACRO_PLACEHOLDER"></script>`;
}

/**
 * Builds the search url with parameters.
 *
 * @param {String} url
 * @param {String} searchTerm
 * @param {String|Number} offset
 * @param {String|Number} count
 * @param {String} market
 * @returns {String}
 */
function buildSearchURL(url, searchTerm, offset, count, market) {
  var params = new URLSearchParams('');
  params.set('q', searchTerm);
  params.set('count', count);
  params.set('mkt', market)
  if (offset) {
    params.set('offset', offset);
  }
  return url + '?' + params.toString();
}

/**
 * Fetches search results.
 * 
 * https://docs.microsoft.com/en-us/rest/api/cognitiveservices/bing-custom-search-api-v7-reference#query-parameters
 *
 * @param {String} query
 * @param {Number} [offset=0]
 * @param {Number} [count=10]
 * @param {String} [market='en-US']
 * @returns {Promise}
 */
async function fetchSearchResults(query = '', offset = 0, count = 10, market = 'en-US') {
  const SEARCH_API_URL = 'https://r0cyolfghh.execute-api.us-west-2.amazonaws.com/development/bing-websearch';
  return fetch(buildSearchURL(SEARCH_API_URL, query, offset, count, market), {
    method: "GET",
    mode: 'cors',
    headers: {
      'Accept': 'application/json'
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(res => {
      if (res.hasOwnProperty('webPages') && res.webPages.totalEstimatedMatches > 0) {
        return res.webPages.value;
      }
      return [];
    })
    .catch(err => {
      err.message = 'Failed to fetch search results: ' + err.message;
      return Promise.reject(err);
    });
}

/**
 * Fetches and returns the product card JSON data.
 *
 * @param {String} productId 
 * @returns {Promise}
 */
async function fetchProductCard(productId) {
  const DATA_URL = `/_site/content/cards/${productId}.json`;
  return fetch(DATA_URL, {
    method: "GET",
    mode: 'cors',
    cache: "no-cache",
    headers: {
      'Accept': 'application/json'
    },
  })
  .then(function(response) {
    return response.json();
  })
  .catch(function(err) {
    err.message = 'Failed to fetch product card data: ' + err.message;
    throw err;
  });
}

export default withStyles(styles)(SearchPage);

export const query = graphql`
  query {
    cards: allFile(filter: { sourceInstanceName: { eq: "cards" } }) {
      edges {
        node {
				  name
        }
      }
    }
    config: allConfigJson {
      edges {
        node {
          tracking_param_list
        }
      }
    }
  }
`
