import React from "react"
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import CategoryIcon from '@material-ui/icons/Category';
import ShippingIcon from '@material-ui/icons/LocalShipping';

import EbayImage from '../images/ebay.svg';

const styles = theme => ({
  root: {
    maxWidth: 680,
    position: 'relative',
    margin: 12,
    verticalAlign: 'top',
    lineHeight: 1.54,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #ddd',
    paddingBottom: 24,
    paddingTop: 12,
  },
  content: {
    width: '100%'
  },
  thumbnail: {
    backgroundSize: 'cover',
    padding: 6,
    marginRight: 16,
    border: '1px solid #ddd',
    maxWidth: 80,
    minWidth: 80,
    minHeight: 80,
    maxHeight: 80,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: 225,
      minWidth: 225,
      minHeight: 120,
      maxHeight: 120,
    },
    '& div': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
      height: '100%',
    }
  },
  title: {
    '& a': {
      textDecoration: 'none',
      color: '#001ba0',
    },
  },
  details: {
    paddingTop: 8,
    display: 'inline-flex',
  },
  category: {
    marginRight: 12,
  },
  price: {
    paddingRight: 12,
    fontWeight: 600,
  },
  logo: {},
  shipping: {},
  icon: {
    fontSize: 16,
    marginRight: 4,
    verticalAlign: 'text-top',
  },
  bottomRow: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
  
})

const ShoppingItem = ({ classes, url, title, price, currency, category, endTime, thumbnail}) => {
  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <div className={classes.thumbnail}>
          <a href={url}>
            <div style={{backgroundImage: `url(${thumbnail})`}} alt='thumbnail'/>
          </a>
        </div>
        <div className={classes.content}>
          <div className={classes.title}>
            <a href={url}>{title}</a>
          </div>
          <div className={classes.details}>
            <div className={classes.category}>
              <CategoryIcon className={classes.icon}/>
              <span>{category}</span>
            </div>
            <div className={classes.shipping}>
              <ShippingIcon className={classes.icon}/>
              <span>Free Shipping</span>
            </div>
          </div>
          <div className={classes.bottomRow}>
            <div className={classes.price}>{currency} ${price}</div>
            <div className={classes.logo}>
              <img src={`${EbayImage}`} alt='ebay logo' width='50'/>
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
  }
  
ShoppingItem.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  currency: PropTypes.string,
  category: PropTypes.string,
  thumbnail: PropTypes.string,
  endTime: PropTypes.string,
};

ShoppingItem.defaultProps = {
  url: '',
  title: '',
  price: '',
  category: '',
  currency: '',
  endTime: '',
  thumbnail: '',
}

export default withStyles(styles)(ShoppingItem);

