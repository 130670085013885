import React from "react"
import { Link } from "gatsby"

import {withStyles} from "@material-ui/core/styles";
import logo from "../images/favicon-dark.ico"

const styles = theme => ({
  root: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& a, a:hover': {
      textDecoration: 'none'
    },
    '& img': {
      margin: 0,
      marginRight: 8
    },
    '& h3': {
      color: "#00c1ef",
      margin: 0,
    },

  }
})

const Logo = ({classes}) => (
  <div className={classes.root}>
    <a href="/">
      <img src={`${logo}`} width="32" height="32" alt="logo" />
      <h3>SearchSwift</h3>
    </a>
  </div>
);

export default withStyles(styles)(Logo)
