import React from "react"
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';

const styles = theme => ({
  root: {
    display: 'block',
    position: 'relative',
    maxWidth: 600,
    maxHeight: 200,
    fontFamily: 'Arial, sans-serif',
    padding: 12,
    marginBottom: 12,
    verticalAlign: 'top',
    border: '1px solid #efefef',
    borderRadius: 2,
    boxSizing: 'border-box',
    overflow: 'hidden',
    '& img[src="https://servedbyadbutler.com/error/blank.gif"]': {
      display: 'none'
    },
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  iconButton: {
    padding: 6
  },
  icon: {
    fontSize: 12
  },
  popupInfo: {
    padding: 12,
  },
  adBadge: {
    position: 'absolute',
    top: 4,
    left: 4,
    color: '#636161',
    fontSize: 8,
    fontWeight: 600,
    border: '1px solid #efefef',
    padding: '0 2px 0 2px',
    borderRadius: 4,
  },
  container: {},
  closeButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});

class TopAdItem extends React.Component {
  state = {
    infoAnchorEl: null
  };

  hideAd() {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      node.hidden = true;
    }
    this.props.hideAd(true);
  }

  handleCloseButtonClick = event => this.hideAd();
  
  handleInfoButtonClick = event => {
    this.setState({
      infoAnchorEl: event.currentTarget,
    });
  };

  handleInfoClose = event => {
    this.setState({
      infoAnchorEl: null,
    });
  };


  render() {
    const {classes, hidden} = this.props;
    const {infoAnchorEl} = this.state;
    const openInfo = Boolean(infoAnchorEl);

    return (
      <div className={classes.root} hidden={hidden}>
        <div className={classes.header}>
          <div className={classes.closeButtonContainer}>
            <IconButton className={classes.iconButton} onClick={this.handleCloseButtonClick}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.adBadge}>Ad</div>
        </div>
        <div id='top-ad-container' className={classes.container}></div>
        <div className={classes.footer}>
          <IconButton className={classes.iconButton} onClick={this.handleInfoButtonClick}>
            <InfoIcon className={classes.icon}/>
          </IconButton>
          <Popover
            id="top-ad-info"
            open={openInfo}
            anchorEl={infoAnchorEl}
            onClose={this.handleInfoClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.popupInfo}>
              <h5 style={{marginBottom: 6}}>About this Ad</h5>
              <p style={{margin: 0, fontSize: 'small'}}>This ad is based on your current search terms</p>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
} 
  
TopAdItem.propTypes = {
  classes: PropTypes.object.isRequired,
  hideAd: PropTypes.func.isRequired,
};

export default withStyles(styles)(TopAdItem);

