import React from "react";
import uuidv4 from 'uuid/v4';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";

import ShoppingItem from "../components/shopping-item";
import LoadingImage from "../images/loading.svg";

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexShrink: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 8,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'normal',
      padding: 0,
    }
  },
  content: {
    maxWidth: '100%',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: '0 12px 0 12px',
    }
  },
  items: {},
  info: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      paddingLeft: 40,
    },
    '& p': {
      fontSize: 13
    }
  },
  loading: {
    minWidth: 300,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    }
  },
  pagination: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
  },
});


class FeedList extends React.Component {

  componentDidMount() {}

  render() {
    const {
      classes,
      items,
      isLoading,
      hidden,
      onPageChange,
      offset,
    } = this.props;

    const buildItems = items => (
      items.map(item =>
        <ShoppingItem
          key={uuidv4()}
          title={item.title[0]}
          url={item.viewItemURL[0]}
          category={item.primaryCategory[0].categoryName[0]}
          price={item.sellingStatus[0].currentPrice[0]['__value__']}
          currency={item.sellingStatus[0].currentPrice[0]['@currencyId']}
          endTime={item.listingInfo[0].endTime[0]}
          thumbnail={item.galleryURL && item.galleryURL.length ? item.galleryURL[0] : ''}
        />
      )
    );

    return (
      <div className={classes.root} hidden={hidden}>
        <div className={classes.content}>
          {/* Loading Image */}
          <div className={classes.loading} hidden={!isLoading}>
            <img width='40' height='40' style={{ textAlign: 'center' }} src={`${LoadingImage}`} alt='loading' />
          </div>
          {/* Feed Items */}
          <div className={classes.items} hidden={isLoading}>
            {items.length ? buildItems(items) : (<p style={{ padding: 20 }}>No results found.</p>)}
          </div>
          <div className={classes.pagination} hidden={isLoading || !items.length}>
            <Pagination
              limit={10}
              offset={offset}
              total={100}
              onClick={(e, offset) => onPageChange(offset)}
              size={'medium'}
            />
          </div>
        </div>
      </div>
    );
  }
}

FeedList.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
}

FeedList.defaultProps = {
 items: [],
 isLoading: false,
}

export default withStyles(styles)(FeedList);
