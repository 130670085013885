import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
};

function MediaCard(props) {
  const { classes, imageSrc, title, body, link } = props;
  return (
    <Card className={classes.card}>
      {imageSrc ? (
        <CardMedia
          className={classes.media}
          image={imageSrc}
          title={`${title} logo`}
        />
      ) : ''}
        <CardContent>
          <h3>{title}</h3>
          <div style={{fontSize: 13}} dangerouslySetInnerHTML={{__html: body}}></div>
        </CardContent>
      {link ? (
        <CardActions>
          <Button size="small" color="primary" href={link} target='_blank'>
            Learn More
          </Button>
        </CardActions>
      ): ''}
      
    </Card>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
};

MediaCard.defaultProps = {
  imageSrc: '',
  title: '',
  body: '',
  link: ''
};

export default withStyles(styles)(MediaCard);