import React from "react"
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    maxWidth: 600,
    fontFamily: 'Arial, sans-serif',
    position: 'relative',
    padding: 12,
    verticalAlign: 'top',
    lineHeight: 1.54,
  },
  title: {
    fontSize: 18,
    display: 'inline-block',
    '& a': {
      color: '#001ba0',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    }
  },
  displayUrl: {
    color: '#006d21',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    paddingBottom: 3,
    paddingTop: 2,
  },
  snippet: {
    wordWrap: 'break-word',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.54,
  }
})

const SearchItem = ({ classes, url, title, snippet}) => {
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <a href={url}>{title}</a>
      </div>
      <div className={classes.displayUrl}>{url}</div>
      <div className={classes.snippet}>{snippet}</div>
    </div>
  );
  }
  
SearchItem.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  title: PropTypes.string,
  snippet: PropTypes.string,
};

SearchItem.defaultProps = {
  url: '',
  title: '',
  snippet: '',
}

export default withStyles(styles)(SearchItem);

